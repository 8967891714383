import { VisuallyHidden } from "@99tech/ui";
import { Box, Stack } from "@mui/material";
import React from "react";
import { EnterSiteButton, HeroBot, HeroMid, HeroTop, Logo } from "src/assets";
import { FloatingBanner } from "src/main/components";
import { createStyles } from "src/main/utils";

interface LandingPageProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">MK69</VisuallyHidden>

      <Stack
        alignItems="center"
        justifyContent="center"
        gap={1}
        my={2}
      >
        <img
          alt="logo"
          src={Logo}
          width={280}
        />

        <Box sx={{ zIndex: 1000, display: "flex", alignItems: "center" }}>
          <img
            alt="enter site button"
            src={EnterSiteButton}
            width={160}
          />
        </Box>
      </Stack>

      <Box sx={styles.hero}>
        <img
          alt="hero bot"
          src={HeroBot}
          width={246}
          style={{ top: "4px", left: "70px", animation: "heroBot 1.2s ease-in infinite" }}
        />
        <img
          alt="hero mid"
          src={HeroMid}
          width={340}
          style={{ top: "179px", left: "10px", animation: "heroMid 1.2s ease-in infinite" }}
        />
        <img
          alt="hero top"
          src={HeroTop}
          width={307}
          style={{ top: "239px", left: "26px", animation: "heroTop 1.2s ease-in infinite" }}
        />
      </Box>

      <FloatingBanner />
    </Box>
  );
};

const styles = createStyles({
  root: {
    pt: "20px",
    pb: "20px",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
  },
  hero: {
    marginTop: "-70px",
    position: "relative",
    width: "360px",
    height: "460px",
    img: {
      position: "absolute",
    },
  },
});

export default LandingPage;
