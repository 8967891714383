import { Box, Container, ContainerProps } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { Bg } from "src/assets";
import { AppContext } from "src/main/constants";
import { createStyles } from "src/main/utils";

interface ContentProps extends React.PropsWithChildren, ContainerProps {}

const Content: React.FC<ContentProps> = (props) => {
  const { children, ...containerProps } = props;

  const { urls } = useContext(AppContext);

  const handleClickPage = useCallback(() => {
    const enterSiteUrl = urls?.enterSiteUrl;
    if (enterSiteUrl) window.open(enterSiteUrl, "_blank");
  }, [urls?.enterSiteUrl]);

  return (
    <Box
      sx={styles.root}
      onClick={handleClickPage}
    >
      <Box sx={styles.bg} />

      <Container
        {...containerProps}
        sx={styles.container}
      >
        {children}
      </Container>
    </Box>
  );
};

const styles = createStyles({
  root: {
    backgroundColor: "black",
    minHeight: "100vh",
    cursor: "pointer",
  },
  bg: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${Bg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    filter: "blur(2px)",
  },
  container: {
    height: "100%",
    px: 0,
  },
});

export default Content;
