import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export const LOGIN_URL = "";
export const REGISTER_URL = "";

export const DEFAULT_ENTER_SITE_URL = "https://a.ntrk2.com/e7fbb98b-cac9-4bbe-922e-e391b32be001";

export const ENTER_SITE_URL_MAP = {
  "www-mk69.co": "https://a.ntrk2.com/e7fbb98b-cac9-4bbe-922e-e391b32be001",
  "www-mk69play.com": "https://a.ntrk2.com/60aea63d-aaa1-4486-82d3-ba733e1eafdb",
};

export const FLOATING_BANNER_URL = "http://mk69thai.net/cs";

export * from "./codes";
export * from "./context";
